import React, { useEffect, useState } from "react";
import { db } from "../../../firebase.config";
import toast from "react-hot-toast";
import Progress from "../../../Components/Progress";
import { scrollTo } from "../../../tools/scroll";
import VideoPlayer2 from "../../../Components/VideoPlayer2";
import IceButtonLong from "../../../Components/Shared/IceButtonLong/IceButtonLong";

const About = () => {
  const [aboutContent, setAboutContent] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaType, setMediaType] = useState(""); // 'image' or 'video'
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    scrollTo(10, 10);

    const fetchAboutContent = async () => {
      try {
        const doc = await db.collection("about").doc("main").get();
        if (doc.exists) {
          const data = doc.data();
          setAboutContent(data.content || "");
          setMediaUrl(data.mediaUrl || "");
          setMediaType(data.mediaType || ""); // Set media type (image/video)
        } else {
          toast.error("No About data found.");
        }
      } catch (error) {
        toast.error("Failed to fetch About section.");
        console.error(error);
      }
    };

    const fetchSkills = async () => {
      try {
        const skillsSnapshot = await db.collection("skills").get();
        const skillsData = skillsSnapshot.docs.map((doc) => doc.data());
        setSkills(skillsData); // Set skills from Firebase
      } catch (error) {
        toast.error("Failed to fetch skills data.");
        console.error(error);
      }
    };

    fetchAboutContent();
    fetchSkills();
  }, []);

  return (
    <section className="my-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 mt-5">
            <h2 className="text-center ">
              <div className="text-center paintedtext buttons abouuut">
                <h2>About Us</h2>
                <h2>About Us</h2>
              </div>
            </h2>
          </div>

          <div className="col-12 skills">
            <div className="row align-items-center about-section">
              <div className="col-12 col-md-6 position-relative">
                {/* Dynamically render media content */}
                {mediaType === "video" ? (
                  <VideoPlayer2 className="videoabout-section" src={mediaUrl} />
                ) : (
                  <img
                    src={mediaUrl}
                    alt="About Media"
                    className="HeaderImageabout"
                    style={{ height: "600px", width: "100%", objectFit: "cover" }}
                  />
                )}
              </div>

              <div className="col-md-6">
                <div
                  className="about-text videoabout-sectiontext fontweight"
                  dangerouslySetInnerHTML={{ __html: aboutContent }}
                />
                <div className="col-md-12 text-center">
                  <a href="/contact">
                    <IceButtonLong label="Request a Free Quote Today!" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Dynamically display the skills from Firebase */}
          {skills.map((skill, index) => (
            <Progress key={index} perchantage={skill} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
