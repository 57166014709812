import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { MdAddIcCall } from "react-icons/md";
import { MdOutgoingMail } from "react-icons/md";
import VideoPlayer from "../../../Components/VideoPlayer";
import IceButtonNormal from "../../../Components/Shared/IceButtonNormal/IceButtonNormal";
import { db } from "../../../firebase.config"; // Adjust the path as needed
import toast from "react-hot-toast";

const TopBanner = () => {
  const [bannerData, setBannerData] = useState(null); // Start with null to indicate loading

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const doc = await db.collection("topbanner").doc("main").get();
        if (doc.exists) {
          setBannerData(doc.data());
        } else {
          toast.error("No banner data found.");
          setBannerData({}); // Avoid indefinite loading state
        }
      } catch (error) {
        toast.error("Failed to fetch banner data.");
        console.error(error);
        setBannerData({}); // Avoid indefinite loading state
      }
    };

    fetchBannerData();
  }, []);

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  // Show a skeleton loader while data is being fetched
  if (!bannerData) {
    return (
      <section className="mt-5">
        <div className="spaceservices mobilelock"></div>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6">
              <div className="fontweight container">
                <h1 className="text-dangen fontweight1 skeleton skeleton-text"></h1>
                <p className="mt-5 mobilelock">
                  {[1, 2, 3].map((_, index) => (
                    <span
                      key={index}
                      className={`ml-md-4 d-md-inline d-block skeleton skeleton-text`}
                    ></span>
                  ))}
                </p>
                <p className="mt-4 skeleton skeleton-paragraph"></p>
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <div className="skeleton skeleton-icon"></div>
                  <div className="skeleton skeleton-icon"></div>
                </div>
                <div className="col-12 mt-5 headerbutton">
                  <div className="skeleton skeleton-button"></div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-md-0 mt-5">
              <div className="text-center">
                <div className="skeleton skeleton-media"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="mt-5">
      <div className="spaceservices mobilelock"></div>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-6">
            <div className="fontweight container">
              <h1 className="text-dangen fontweight1">{bannerData.title}</h1>
              <p className="mt-5 mobilelock">
                {chunkArray(bannerData.qualities, 3).map((chunk, chunkIndex) => (
                  <div key={chunkIndex} className="d-block">
                    {chunk.map((quality, index) => (
                      <span key={index} className="d-inline-block mr-4">
                        <FontAwesomeIcon
                          style={{ marginRight: "8px" }}
                          color="green"
                          icon={faCheck}
                        />
                        {quality}
                      </span>
                    ))}
                  </div>
                ))}
              </p>
              <p className="mt-4">{bannerData.description}</p>
              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <a
                  aria-label="Email Rullan Malningar"
                  href="mailto:rullanmalningar@gmail.com"
                >
                  <MdOutgoingMail size={36} className="text-dangen" />
                </a>
                <a aria-label="phone Rullan Malningar" href="tel:+3546974563">
                  <MdAddIcCall size={36} className="text-dangen" />
                </a>
              </div>
              <div className="col-12 mt-5 headerbutton">
                <Link to="/contact">
                  <IceButtonNormal label="Get In Touch!" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-md-0 mt-5">
            <div className="text-center">
              {bannerData.mediaType === "video" ? (
                <VideoPlayer src={bannerData.mediaUrl} />
              ) : (
                <img
                  src={bannerData.mediaUrl}
                  alt={bannerData.title || "Banner"}
                  className="HeaderImage"
                  style={{ height: "100%", width: "100%", objectFit: "cover", paddingLeft:"0", }}
               
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
